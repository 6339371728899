@use '@angular/material' as mat;
@use 'sass:map';
@include mat.core();


$gem-green: #244952;
$gem-green-light: #D2DBDC;

/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'ClashDisplay';
    src: url('assets/fonts/ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Monstserrat';
  src: url('assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monstserrat Medium';
  src: url('assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monstserrat Light';
  src: url('assets/fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Zodiak';
  src: url('assets/fonts/Zodiak_Complete/Fonts/WEB/fonts/Zodiak-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Zodiak Light';
  src: url('assets/fonts/Zodiak_Complete/Fonts/WEB/fonts/Zodiak-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Monstserrat', sans-serif;
    // color: #244952;
    list-style-type: none;
    font-size: 14px;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; 
  /* font-size: 8px; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-option-text:hover:not(.mat-option-disabled) {
  background: #3071a9 !important;
  color: white;
}

.mat-option:hover {
  background: red !important;
  color: green !important;
}

::ng-deep {
  .mdc-evolution-chip__graphic .mdc-evolution-chip__checkmark{
    width: 0px !important;
  }
  
}

$md-arrowgreen: (
    50 : #e5e9ea,
    100 : #bdc8cb,
    200 : #92a4a9,
    300 : #668086,
    400 : #45646c,
    500 : #244952,
    600 : #20424b,
    700 : #1b3941,
    800 : #163138,
    900 : #0d2128,
    A100 : #67d4ff,
    A200 : #34c5ff,
    A400 : #01b6ff,
    A700 : #00a5e6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$arrow-primary: mat.define-palette($md-arrowgreen);
$my-app-accent:  mat.define-palette(mat.$pink-palette);
$my-app-warn:    mat.define-palette(mat.$red-palette);

$arrow-theme: mat.define-light-theme((
    color: (
        primary: $arrow-primary,
        accent: $my-app-accent,
        warn: $my-app-warn
    ),
    density: -3
));

@include mat.all-component-themes($arrow-theme);